import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useQueryParam } from "gatsby-query-params";

import Layout from "components/Layout";
import SEO from "components/Seo";
import Search from "components/Search";

const SearchPage = () => {
  const searchParam = useQueryParam("q", "");
  // console.log("SearchPage -> searchParam: ", searchParam);

  return (
    <Layout pageInfo={{ pageName: "search" }}>
      <SEO title="Search" />
      {/* <Banner title="FAQs" /> */}

      <section>
        <Container>
          <Row>
            <Col>
              <h2>Site Search</h2>

              <p>
                If you're having trouble finding what you are looking for, try
                our site search:
              </p>
            </Col>
          </Row>

          <Row>
            <Col xs="12" md="10">
              <Search initQuery={searchParam} />
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
};

export default SearchPage;
