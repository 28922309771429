import React, { useState, useEffect } from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import { useFlexSearch } from "react-use-flexsearch";
import FlexSearch from "flexsearch";
import { Formik, Form, Field } from "formik";

import { formatToMilitaryDate } from "helpers/index";

const findusIndex = new FlexSearch("speed");
findusIndex.add(1, "locations");
findusIndex.add(2, "range");
findusIndex.add(3, "classroom");
findusIndex.add(4, "address");
findusIndex.add(
  5,
  "To provide the best training possible, D&D Security Training Academy utilizes both a classroom location and a separate range location. You can find details for both below, including address, driving directions, and a Google map."
);

const findusStore = {
  1: { id: 1, title: "Find Us", path: "/find-us/" },
  2: { id: 2, title: "Find Us", path: "/find-us/" },
  3: { id: 3, title: "Find Us", path: "/find-us/" },
  4: { id: 4, title: "Find Us", path: "/find-us/" },
  5: { id: 5, title: "Find Us", path: "/find-us/" },
};

const SearchBar = ({ initQuery = "" }) => {
  const [query, setQuery] = useState("");
  // console.log("SearchBar -> initQuery", initQuery);

  useEffect(() => {
    setQuery(initQuery);
  }, [initQuery]);

  const data = useStaticQuery(graphql`
    query FlexsearchQuery {
      localSearchBranches {
        index
        store
      }
      localSearchFaqs {
        index
        store
      }
      localSearchSchedules {
        index
        store
      }
      localSearchSchedulesindex {
        store
        index
      }
    }
  `);

  const { index, store } = data.localSearchBranches;
  const parsedStore = store ? store : {};

  const schedsIndex = data.localSearchSchedules.index;
  const schedsStore = data.localSearchSchedules.store;
  const parsedSchedsStore = schedsStore ? schedsStore : {};

  const faqsIndex = data.localSearchFaqs.index;
  const faqsStore = data.localSearchFaqs.store;
  const parsedFaqsStore = faqsStore ? faqsStore : {};

  const schedsHomeIndex = data.localSearchSchedulesindex.index;
  const schedsHomeStore = data.localSearchSchedulesindex.store;
  const parsedSchedsHomeStore = schedsHomeStore
    ? schedsHomeStore
    : {};

  const branchesResults = useFlexSearch(query, index, parsedStore);
  const findusResults = useFlexSearch(query, findusIndex, findusStore);
  const schedResults = useFlexSearch(query, schedsIndex, parsedSchedsStore);
  const faqsResults = useFlexSearch(query, faqsIndex, parsedFaqsStore);
  const schedsHomeResults = useFlexSearch(
    query,
    schedsHomeIndex,
    parsedSchedsHomeStore
  );
  // console.log("SearchBar -> schedResults", schedResults);
  // console.log("SearchBar -> findusResults", findusResults);

  return (
    <div>
      <Formik
        initialValues={{ query: "" }}
        onSubmit={(values, { setSubmitting }) => {
          setQuery(values.query);
          setSubmitting(false);
        }}
      >
        <Form>
          <Field
            name="query"
            className="form-control"
            style={{ maxWidth: "300px" }}
          />
        </Form>
      </Formik>

      <h4 className="pt-4">Search Results for: "{query}"</h4>

      {branchesResults.length > 0 && (
        <div className="pt-3">
          <h5>Training Branch Pages:</h5>
          <ul>
            {branchesResults.map((result, i) => (
              <li key={i}>
                <Link to={result.path}>{result.title}</Link>
              </li>
            ))}
          </ul>
        </div>
      )}

      {schedsHomeResults.length > 0 && (
        <div className="pt-3">
          <h5>Schedules Main Page:</h5>

          <ul>
            {schedsHomeResults.map(result => (
              <li key={result.id}>
                <Link to={result.path}>{result.lead_text}</Link>
              </li>
            ))}
          </ul>
        </div>
      )}

      {schedResults.length > 0 && (
        <div className="pt-3">
          <h5>Scheduled Classes:</h5>

          <ul>
            {schedResults.map((result, i) => (
              <li key={i}>
                <Link to={result.path}>{`${
                  result.title
                } - ${formatToMilitaryDate(result.date)}`}</Link>
              </li>
            ))}
          </ul>
        </div>
      )}

      {faqsResults.length > 0 && (
        <div className="pt-3">
          <h5>Faqs:</h5>

          <ul>
            {faqsResults.map((result, i) => (
              <li key={i}>
                <Link to={result.path}>{result.question}</Link>
              </li>
            ))}
          </ul>
        </div>
      )}

      {findusResults.length > 0 && (
        <div className="pt-3">
          <h5>Find Us Page:</h5>

          <ul>
            {findusResults.map((result, i) => (
              <li key={i}>
                <Link to={result.path}>{result.title}</Link>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default SearchBar;
